import lozad from 'lozad';

const LazyLoad = new function() {

    this.init = function (node) {

        var pictureObserver;

        function loadImage() {

            pictureObserver = lozad('.lazy-load-item', {
                threshold: 0,
                loaded: function(el) {
                    el.classList.add('asset-loaded');
                }
            })
            pictureObserver.observe();

        }

        if(document.readyState === 'complete') {
            loadImage();
        }
        else {

            document.addEventListener('readystatechange', function(e) {

                if( e.target.readyState === 'complete' ) {
                    loadImage();
                }

            });

        }

        //zacznij ładować poniższe zdjęcia zaraz po zaladowaniu się podstawowej wersji strony
        window.addEventListener('load', function () {
            const coolImage = document.querySelectorAll('.lazy-load-item-ar');
            coolImage.forEach(item => pictureObserver.triggerLoad(item));
        })

    }

};
export default LazyLoad;